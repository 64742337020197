@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("Lato-Light.2e272421.ttf");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Lato-Regular.ad872c70.ttf");
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Lato-Bold.f53d8879.ttf");
}

@font-face {
  font-family: Material Icons Round;
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("MaterialIconsRound-Regular.803d62ee.otf");
}

html, body {
  height: 100%;
  margin: 0;
  font-family: lato;
  font-size: 16px;
  overflow: hidden;
}

#imbiblioapp-reader-loader #iapp-loader {
  width: 2vw;
  height: 2vw;
  z-index: 1500;
  background: #cea942;
  border-radius: 50%;
  animation: 2.3s linear infinite forward;
  position: absolute;
  top: 50%;
  left: 50%;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(1) {
  animation-delay: -.46s;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(2) {
  animation-delay: -.92s;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(3) {
  animation-delay: -1.38s;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(4) {
  animation-delay: -1.84s;
}

@keyframes forward {
  0% {
    opacity: 0;
    background: #cea942;
    left: 40%;
  }

  10% {
    opacity: 1;
    left: 45%;
  }

  90% {
    opacity: 1;
    left: 55%;
  }

  100% {
    opacity: 0;
    left: 62%;
  }
}

/*# sourceMappingURL=index.8c0195bd.css.map */
