@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/fonts/Lato-Light.ttf);
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(./assets/fonts/MaterialIconsRound-Regular.otf);
}

html,
body {
  margin: 0;
  font-size: 16px;
  font-family: lato;
  height: 100%;
  overflow: hidden;
}

#imbiblioapp-reader-loader #iapp-loader {
  position: absolute;
  width: 2vw;
  height: 2vw;
  background: #cea942;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  z-index: 1500;
  -webkit-animation: forward 2.3s linear infinite;
  -moz-animation: forward 2.3s linear infinite;
  -o-animation: forward 2.3s linear infinite;
  animation: forward 2.3s linear infinite;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(1) {
  -webkit-animation-delay: -0.46s;
  -moz-animation-delay: -0.46s;
  -o-animation-delay: -0.46s;
  animation-delay: -0.46s;
}

#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(2) {
  -webkit-animation-delay: -0.92s;
  -moz-animation-delay: -0.92s;
  -o-animation-delay: -0.92s;
  animation-delay: -0.92s;
}
#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(3) {
  -webkit-animation-delay: -1.38s;
  -moz-animation-delay: -1.38s;
  -o-animation-delay: -1.38s;
  animation-delay: -1.38s;
}
#imbiblioapp-reader-loader > #iapp-loader:nth-of-type(4) {
  -webkit-animation-delay: -1.84s;
  -moz-animation-delay: -1.84s;
  -o-animation-delay: -1.84s;
  animation-delay: -1.84s;
}

/*keyframes for forward animations*/

@-webkit-keyframes forward {
  0% {
    left: 40%;
    opacity: 0;
    background: #cea942;
  }
  10% {
    left: 45%;
    opacity: 1;
  }
  90% {
    left: 55%;
    opacity: 1;
  }
  100% {
    left: 62%;
    opacity: 0;
  }
}

@-moz-keyframes forward {
  0% {
    left: 40%;
    opacity: 0;
    background: #cea942;
  }
  10% {
    left: 45%;
    opacity: 1;
  }
  90% {
    left: 55%;
    opacity: 1;
  }
  100% {
    left: 62%;
    opacity: 0;
  }
}

@-o-keyframes forward {
  0% {
    left: 40%;
    opacity: 0;
    background: #cea942;
  }
  10% {
    left: 45%;
    opacity: 1;
  }
  90% {
    left: 55%;
    opacity: 1;
  }
  100% {
    left: 62%;
    opacity: 0;
  }
}

@keyframes forward {
  0% {
    left: 40%;
    opacity: 0;
    background: #cea942;
  }
  10% {
    left: 45%;
    opacity: 1;
  }
  90% {
    left: 55%;
    opacity: 1;
  }
  100% {
    left: 62%;
    opacity: 0;
  }
}
